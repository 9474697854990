import React from 'react'
import './home.css'
import 'boxicons'
import img from '../images/img.gif'
function Home() {
  // let menuIcon=document.querySelector('#menu-icon')
  // let navbar=document.querySelector('.navbar')
  // let sections=document.querySelector('section')
  // let navLinks=document.querySelector('header nav a')
  
  // window.onscroll=()=>{
  //   sections.forEach(sec => {
  //     let top=window.scrollY
  //     let offset=sec.offsetTop-150
  //     let height= sec.offsetHeight
  //     let id=sec.getAttribute('id')

  //     if(top >= offset && top < offset + height){
  //       navLinks.forEach(links=>{links.classList.remove('active')})
  //       document.querySelector('header nav a [href*='+id+']').classList.add('active')
  //     }
  //   });
  // }
  // menuIcon.onlClick=()=>{
  //   menuIcon.classList.toggle('bx-x')
  //   navbar.classList.toggle('active')
  // }
  return (
    <div>
      <header className='header'>
        <a href='' className='logo'>Nandha<span>kumar</span>
        <i className='bx bx-menu' id='menu-icon'></i></a>
        <nav className='navbar'>
          <a href='' className='active'>Home</a>
          <a href=''>Education</a>
          <a href=''>Projects</a>
          <a href=''>Skills</a>
          <a href=''>Contact</a>
        </nav>
      </header>

      <section className='home' id='home'>
        <div className='home-content'>
            <h1>Hi, It's <span>Nandhakumar</span></h1>
            <h3 className='text-animation'>I'm a <span></span></h3>
            <p>Asdgghjjjkklouytrwqas</p>

            <div className='social-icons'>
                <a href=''><i className='bx bxl-linkedin' ></i></a>
                <a href=''><i className='bx bxl-github' ></i></a>
                <a href=''><i className='bx bxl-twitter' ></i></a>
                <a href=''><i className='bx bxl-instagram-alt' ></i></a>
            </div>
            <div className='btn-group'>
                <a href='' className='btn'>Hire</a>
                <a href='' className='btn'>Contact</a>
            </div>
        </div>
        <div className='home-img'>
          <img src={img}/>
        </div>
      </section>
      <section className='education' id='education'>
        <h2 className='heading'>Education</h2>

        <div className='timeline-items'>
          <div className='timeline-item'>
            <div className='timeline-dot'></div>
              <div className='timeline-date'>2019-2020</div>
              <div className='timeline-content'>
                <h3>High School</h3>
                <p>Government Higher Secondary School</p>
                <p>Sirumugaipudur Coimbatore Tamilnadu</p>
              </div>
          </div>

          <div className='timeline-item'>
            <div className='timeline-dot'></div>
              <div className='timeline-date'>2020-2023</div>
              <div className='timeline-content'>
                <h3>UG</h3>
                <p>BSC Computer Science With Data Analytics</p>
                <p>United College Of Arts And Science Periyanaickenpalayam Coimbatore</p>
              </div>
          </div>

          <div className='timeline-item'>
            <div className='timeline-dot'> </div>
              <div className='timeline-date'>2024</div>
              <div className='timeline-content'>
                <h3>Internship</h3>
                <p>Gt Software College</p>
                <p>Race Course Coimbatore</p>
              </div>
          </div>

          <div className='timeline-item'>
            <div className='timeline-dot'> </div>
              <div className='timeline-date'>2024</div>
              <div className='timeline-content'>
                <h3>PG</h3>
                <p>MCA</p>
                <p>CMS College Of Science And Commerce Saravanampatti Coimbatore</p>
              </div>
          </div>
        </div>
      </section>

      <section className='skills' id='skills'>
        <h2>Skills</h2>
        <div className='skills-container'>
          <div className='skills-box'>
            <div className='skills-info'>
              <h4>Web Developer</h4>
              <p>aaaaaaaaaaaaaaaaaaaaaaabbbbbbbbbbbbbbbbbbbbbbbbbbbbbbcccccccccccccccccccccccccccccdddddddddddddddddddd</p>
            </div>
          </div>

          <div className='skills-box'>
            <div className='skills-info'>
              <h4>Frontend Developer</h4>
              {/* <p>aaaaaaaaaaaaaaaaaaaaaaabbbbbbbbbbbbbbbbbbbbbbbbbbbbbbcccccccccccccccccccccccccccccdddddddddddddddddddd</p> */}
            </div>
          </div>

          <div className='skills-box'>
            <div className='skills-info'>
              <h4>Backend Developer</h4>
              {/* <p>aaaaaaaaaa aaaaa aaaaaa aabbbbbbb bbbbbbbbb bbbbbbbbbbb bbbccccc ccccccccc cccccccccccccccdddddddddddddddddddd</p> */}
            </div>
          </div>
          <div className='skills-box'>
            <div className='skills-info'>
              <h4>Web Developer</h4>
              {/* <p>aaaaaaaaaaaaaaaaaaaaaaabbbbbbbbbbbbbbbbbbbbbbbbbbbbbbcccccccccccccccccccccccccccccdddddddddddddddddddd</p> */}
            </div>
          </div>

        </div>
      </section>

      <section className='projects' id='projects'>
        <div className='projects-box'>
          <h2 className='heading'>Projects</h2>
          <div className='wrapper'>
            <div className='project-item'>
              <img src={img}/>
              <h2>Main</h2>
              <div className='rating'>
              <i className='bx bxs-star' id='star'></i>
              <i className='bx bxs-star' id='star'></i>
              <i className='bx bxs-star' id='star'></i>
              <i className='bx bxs-star' id='star'></i>
              <i className='bx bxs-star' id='star'></i>
              </div>
              <p>F and F</p>
            </div>

            <div className='project-item'>
              <img src={img}/>
              <h2>Main</h2>
              <div className='rating'>
              <i className='bx bxs-star' id='star'></i>
              <i className='bx bxs-star' id='star'></i>
              <i className='bx bxs-star' id='star'></i>
              <i className='bx bxs-star' id='star'></i>
              <i className='bx bxs-star' id='star'></i>
              </div>
              <p>gsgwjh hjjwhhbdb hjqjjiuyd bdjwh dwhhhhhhhw qbnn qihhdb jjdbkkh jkdyiug bduuuh hdioiodh djkdhh</p>
            </div>

            <div className='project-item'>
              <img src={img}/>
              <h2>Main</h2>
              <div className='rating'>
              <i className='bx bxs-star' id='star'></i>
              <i className='bx bxs-star' id='star'></i>
              <i className='bx bxs-star' id='star'></i>
              <i className='bx bxs-star' id='star'></i>
              <i className='bx bxs-star' id='star'></i>
              </div>
              <p>gsgwjh hjjwhhbdb hjqjjiuyd bdjwh dwhhhhhhhw qbnn qihhdb jjdbkkh jkdyiug bduuuh hdioiodh djkdhh</p>
            </div>
          </div>

          
        </div>
      </section>

      <section className='contact' id='contact'>
        <h2 className='heading'>Contact<span>Me</span></h2>

        <form action=''>
          <div className='input-group'>
            <div className='input-box'>
              <input type='text' placeholder='Full Name'/>
              <input type='email' placeholder='Email'/>
            </div>
            <div className='input-box'>
              <input type='number' placeholder='Phone Number'/>
              <input type='text' placeholder='Subject'/>
            </div>
          </div>
          <div className='input-group-2'>
            <textarea name='' id='' cols={30} rows={10} placeholder='Your Message'></textarea>
            <input type='submit' value={"Send Message"} className='btn'/>
          </div>
        </form>
      </section>
      <footer className='footer'>
        <div className='social'>
        <a href=''><i className='bx bxl-linkedin' ></i></a>
                <a href=''><i className='bx bxl-github' ></i></a>
                <a href=''><i className='bx bxl-twitter' ></i></a>
                <a href=''><i className='bx bxl-instagram-alt' ></i></a>
        </div>

        <ul className='list'>
          <li><a href=''>FAQ</a></li>
          <li><a href=''>Services</a></li>
          <li><a href=''>About Me</a></li>
          <li><a href=''>Contact</a></li>
          <li><a href=''>Projects</a></li>
        </ul>
        <p className='copyright'>© Nandhakumar | All Rights Reserved</p>
      </footer>
    </div>
  )
}
export default Home